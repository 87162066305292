<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <BaseDialog
      :isValid="!invalid"
      :isLoading="isLoading"
      title="Adicionar Usuário"
      @handlerSave="verifyTypeForm"
      width="60%"
    >
      <v-row>
        <v-col cols="6">
          <BaseInputValidator
            type="textField"
            label="Nome *"
            filled
            rounded
            dense
            v-model="form.name"
            fieldLayout="filled"
            required
            :rules="{ required: true }"
          >
          </BaseInputValidator>
        </v-col>
        <v-col cols="6">
          <BaseInputValidator
            type="autoComplete"
            label="Tipo *"
            fieldLayout="filled"
            rounded
            dense
            itemValue="value"
            itemText="name"
            v-model="form.type"
            :items="types"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <BaseInputValidator
            type="textField"
            label="Email (Login) *"
            @keydown.native.space.prevent
            filled
            rounded
            dense
            v-model="form.email"
            fieldLayout="filled"
            required
            :rules="{ required: true }"
          >
          </BaseInputValidator>
        </v-col>
        <v-col cols="6">
          <BaseInputValidator
            type="textField"
            typeForm="password"
            label="Password *"
            filled
            rounded
            dense
            v-model="form.password"
            fieldLayout="filled"
            required
            :rules="{ required: true }"
          >
          </BaseInputValidator>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <BaseInputValidator
            type="autoComplete"
            label="Cliente"
            fieldLayout="filled"
            rounded
            dense
            itemValue="_id"
            itemText="corporateName"
            v-model="form.customer"
            :items="customers.docs"
          />
        </v-col>
      </v-row>
    </BaseDialog>
  </validation-observer>
</template>

<script>
import BaseInputValidator from "../../components/UI/BaseInputValidator";
import { ValidationObserver } from "vee-validate";
import UserService from "./service";
import terminalService from "../terminal/service";
import customerService from "../customers/service";
import BaseDialog from "../../components/UI/BaseDialog";

export default {
  name: "RegistrationUser",
  data() {
    return {
      dialog: true,
      isValid: false,
      isLoading: false,
      terminals: [],
      email: "",
      customers: [],
      form: {
        name: "",
        email: "",
        password: "",
        type: "",
        terminals: [],
        customer: "",
      },
      typeForm: "",
      types: [
        {
          name: "Cliente",
          value: "customer",
        },
        { name: "Franqueado", value: "franchisee" },
        { name: "Backoffice", value: "backoffice" },
        { name: "Tecnico", value: "technician" },
      ],
    };
  },
  methods: {
    async getTerminals() {
      try {
        const terminals = await terminalService.getTerminals();
        this.terminals = terminals.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getCustomers() {
      try {
        const customers = await customerService.getCustomers({
          pagination: false,
        });
        this.customers = customers.data;
      } catch (error) {
        console.error(error);
      }
    },
    async sendUserInfo() {
      try {
        const user = await UserService.createUser(this.form);
        return user;
      } catch (error) {
        console.error(error);
      }
    },
    async verifyTypeForm() {
      if (this.typeForm === "EditUser") {
        const user = await this.updateUser();
        this.$router.push({ name: "User" });
        this.$emit("created", { user: user, typeForm: this.typeForm });
      } else {
        const user = await this.sendUserInfo();
        this.$router.push({ name: "User" });
        this.$emit("created", { user: user, typeForm: this.typeForm });
      }
    },
    async updateUser() {
      try {
        const user = await UserService.updateUser(
          this.form,
          this.$route.params.id
        );
        return user;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    await this.getTerminals();
    await this.getCustomers();
    this.typeForm = this.$route.name;
    if (this.typeForm === "EditUser") {
      const user = await UserService.getUserById(this.$route.params.id);
      this.form = user.data;
    }
  },
  components: {
    BaseInputValidator,
    ValidationObserver,
    BaseDialog,
  },
};
</script>
